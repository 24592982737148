<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ManagementManage',

  components: {
    Action: () => import('@/components/general/Action'),
    FormSection: () => import('@/components/general/FormSection'),
    InputField: () => import('@/components/general/InputField'),
    Modal: () => import('@/components/general/Modal'),
    Radio: () => import('@/components/general/Radio'),
    SelectField: () => import('@/components/general/SelectField'),
    // TextareaField: () => import('@/components/general/TextareaField'),
    ValidationMessage: () => import('@/components/general/ValidationMessage')
  },

  data () {
    return {
      formData: {
        id: null,
        name: null,
        active: null,
        hasExtraField: false,
        extraFields: []
      },

      yesNoOptions: [
        {
          label: this.$t('global:yes'),
          value: true
        },
        {
          label: this.$t('global:no'),
          value: false
        }
      ],

      extraFieldTypeOptions: [
        {
          text: this.$t('management.index:modal.form.type.simple_text'),
          value: 'text',
          disabled: false
        },
        {
          text: this.$t('management.index:modal.form.type.unique'),
          value: 'unique',
          disabled: false
        },
        {
          text: this.$t('management.index:modal.form.type.multiple'),
          value: 'multiple',
          disabled: true
        }
      ]
    }
  },

  validations: {
    formData: {
      name: {
        required
      },

      extraFields: {
        required: requiredIf(function () {
          return this.formData.hasExtraField
        }),

        $each: {
          title: { required },
          type: { required },
          options: {
            required: requiredIf(function (item) {
              return [ 'unique' ].indexOf(item.type) > -1
            }),

            $each: {
              name: {
                required: requiredIf(function (item) {
                  return [ 'unique' ].indexOf(item.type) > -1
                })
              }
            }
          }
        }
      }
    }
  },

  computed: {
    ...mapState([ 'fetching' ]),
    isEditing () {
      return !!this.$route.params.id
    },

    title () {
      if (this.isEditing) {
        return this.$t('management.index:modal.title.editing')
      }

      return this.$t('management.index:modal.title.add')
    }

    // targetAudienceManage: {
    //   get (state) {
    //     return this.$store.state.Management.targetAudienceManage || []
    //   }
    // }
  },

  created () {
    if (this.isEditing) {
      const targetAudienceManage = this.$store.state.Management.targetAudiences.filter((audience) => {
        return audience.id === this.$route.params.id
      })[0]

      if (!targetAudienceManage) {
        this.setFeedback({ message: this.$t('management:feedback.edit.error') })
        this.closeModal()
      } else {
        this.formData.name = targetAudienceManage.name

        this.formData.hasExtraField = targetAudienceManage.targetAudienceQuestion
          .length > 0

        if (this.formData.hasExtraField) {
          this.formData.extraFields = this.formatOptions(targetAudienceManage.targetAudienceQuestion
          )
        }
      }
    }
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptSaveTargetAudience',
      'attemptEditTargetAudience'
    ]),

    submit () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.isEditing ? this.submitEdit() : this.submitCreation()
    },

    submitCreation () {
      this.setFetching(true)
      this.active = true

      return this.attemptSaveTargetAudience(this.formData).then(() => {
        this.setFeedback({ message: this.$t('management:feedback.created.success') })
        this.closeModal()
      }).catch(e => {
        if (this.getErrorMessage(e) === '') {
          this.setFeedback({ message: this.$t('management.ent.profile:feedback.already.exists.error') })
        } else {
          this.setFeedback({ message: this.$t('management:feedback.created.error') })
        }
      }).finally(() => {
        this.setFetching(false)
      })
    },

    submitEdit () {
      this.setFetching(true)
      this.formData.id = this.$route.params.id

      return this.attemptEditTargetAudience(this.formData).then(() => {
        this.setFeedback({ message: this.$t('management:feedback.edit.success') })
        this.closeModal()
      }).catch(e => {
        if (this.getErrorMessage(e) === 'entrepreneur_profile_already_exists_with_name') {
          this.setFeedback({ message: this.$t('management.ent.profile:feedback.already.exists.error') })
        } else {
          this.setFeedback({ message: this.$t('management:feedback.edit.error') })
        }
      }).finally(() => {
        this.setFetching(false)
      })
    },

    closeModal () {
      this.$router.push({ name: 'management.index' })
    },

    getErrorMessage (e) {
      if (e.data && e.data.error && e.data.error.message) {
        return e.data.error.message
      }

      return null
    },

    addExtraField () {
      if (this.formData.extraFields.length < 3) {
        this.formData.extraFields.push({
          required: true,
          type: 'text',
          title: null,
          options: []
        })
      }
    },

    removeExtraField (index) {
      this.formData.extraFields.splice(index, 1)
    },

    addOption (field) {
      field.options.push({
        name: null,
        value: null
      })

      this.$forceUpdate()
    },

    removeOption (field, index) {
      field.options.splice(index, 1)
      this.$forceUpdate()
    },

    formatOptions (options) {
      return options.map(item => {
        item.options = item.targetAudienceQuestionOption

        return item
      })
    }
  }
}
</script>

<template>
  <Modal
    :active="!fetching"
    @close="closeModal"
  >
    <div class="modal-form modal-add-user">
      <span class="modal-subtitle">{{ $t('management:modal.subtitle') }}</span>
      <h2 class="modal-title">
        {{ title }}
      </h2>
      <p class="modal-subtext">
        {{ $t('management.index:modal.subtext') }}
      </p>
      <span
        class="modal-form-description"
        v-html="$t('management.index:modal.description')"
      />

      <form @submit.prevent="submit()">
        <InputField
          v-model="formData.name"
          dark
          :validation="$v.formData.name"
          :label="$t('management.index:modal.form.title')"
        />

        <FormSection :title="$t('management.index:modal.form.has_extra_fields')">
          <Radio
            v-model="formData.hasExtraField"
            dark
            horizontal
            :items="yesNoOptions"
            :under-description="$t('management.index:modal.form.has_extra_fields.under_description')"
          />
        </FormSection>

        <FormSection v-if="formData.hasExtraField">
          <ul class="extra-fields">
            <li
              v-for="(field, fieldIndex) in formData.extraFields"
              :key="fieldIndex"
            >
              <FormSection :title="field.title ? field.title : $tc('management.index:modal.form.extra_field_number', fieldIndex, { index: fieldIndex + 1 })">
                <SelectField
                  v-model="field.type"
                  :label="$t('management.index:modal.form.select_type')"
                  :validation="$v.formData.extraFields.$each[fieldIndex].type"
                  :items="extraFieldTypeOptions"
                  dark
                />

                <InputField
                  v-model="field.title"
                  :under-description="$t('management.index:modal.form.title.under_description')"
                  :validation="$v.formData.extraFields.$each[fieldIndex].title"
                  :label="$t('management.index:modal.form.extra_field_title')"
                  dark
                />

                <template v-if="field.type === 'unique'">
                  <Action
                    :text="$t('management.index:modal.form.add_extra_field_option')"
                    primary
                    dark
                    tiny
                    class="add-option"
                    icon="add"
                    icon-size="small"
                    type="button"
                    @click="addOption(field)"
                  />

                  <ul
                    v-if="field.options.length > 0"
                    class="extra-field-items"
                  >
                    <li
                      v-for="(option, optionIndex) in field.options"
                      :key="optionIndex"
                      class="extra-field-item flex-row"
                    >
                      <!--
 :validation="$v.formData.extraFields.$each[fieldIndex].options.$each[optionIndex].name"
-->
                      <InputField
                        v-model="option.name"
                        :label="`Item ${optionIndex + 1}`"
                        :disabled="field.disabled == 1"
                        :counter="100"
                        hide-optional
                        dark
                      />
                      <Action
                        icon="delete"
                        icon-right
                        dark
                        flat
                        type="button"
                        @click="removeOption(field, optionIndex)"
                      />
                    </li>
                  </ul>

                  <div
                    v-if="formData.extraFields[fieldIndex].options.length === 0"
                    class="form-item theme-dark"
                  >
                    <ValidationMessage :validation="$v.formData.extraFields.$each[fieldIndex].options" />
                  </div>
                </template>

                <Radio
                  v-model="field.required"
                  dark
                  horizontal
                  :items="yesNoOptions"
                  :under-description="$t('management.index:modal.form.extra_field_required')"
                />

                <Action
                  type="button"
                  dark
                  primary
                  icon="delete"
                  :text="$t('management.index:modal.form.remove_extra_field')"
                  @click="removeExtraField(fieldIndex)"
                />
              </FormSection>
            </li>
          </ul>

          <div
            v-if="formData.extraFields.length === 0"
            class="form-item theme-dark"
          >
            <ValidationMessage :validation="$v.formData.extraFields" />
          </div>

          <Action
            type="button"
            dark
            primary
            medium
            icon="add"
            :disabled="formData.extraFields.length >= 3"
            :text="formData.extraFields.length < 3 ? $t('management.index:modal.form.add_extra_field') : $t('management.index:modal.form.limit_reached')"
            @click="addExtraField"
          />
        </FormSection>

        <div class="form-submit text-center">
          <Action
            type="button"
            secondary
            large
            submit
            fixed-width
            :text="$t('global:form.save')"
          />
        </div>
      </form>
    </div>
  </Modal>
</template>

<style src="@/assets/styles/themes/default/management.css"></style>
